
import { ref } from "vue"

export default {
  name: "LabelComponent",
  emits: ["edit-label", "cancel-edit", "delete-label-from-task"],
  props: {
    label: {
      type: Object,
      required: true,
    },
    connectedToTask: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, { emit }: any) {
    const newLabelAlias = ref(props.label.alias)

    function editLabel(event: any) {
      const modifiedLabel = {
        alias: newLabelAlias.value,
        code: props.label.code,
      }
      emit("edit-label", modifiedLabel, props.label.id)
    }

    function cancelEditLabel(event: any) {
      newLabelAlias.value = props.label.alias
      emit("cancel-edit", props.label)
      event.stopPropagation()
    }

    function deleteLabelFromTask() {
      emit("delete-label-from-task", props.label)
    }

    return {
      newLabelAlias,
      editLabel,
      cancelEditLabel,
      deleteLabelFromTask,
    }
  },
}
